import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import classNames from 'classnames'

import { Header, Footer } from 'src/components/layout'
import Heading from 'src/components/heading/Heading'
import Button from 'src/components/button'
import DownloadSection from 'src/components/pages/shared/DownloadSection'
import buildImageObject from 'src/js/utils/buildImgObject'
import fetchPageData from 'src/js/utils/fetchPageData'
import { useRellax } from 'src/js/utils/hooks'

/*
 * A template for ramp landing pages
 */

// Styles & Images:
import 'static/ramp/scss/styles.scss'

const images = {
  background: {
    aspectRatio: 1.305,
    path: '/ramp/img/header-bg/',
    fileType: 'jpg',
    webp: true,
    files: [
      {
        fileName: 'header-bg-800',
        width: 800,
      },
      {
        fileName: 'header-bg-1200',
        width: 1200,
      },
    ],
  },
}

// Partials:
const Assets = ({ assets, rellax = false }) => (
  <div className="x__assets">
    {assets.map((asset, index) => (
      <span
        key={index}
        className={classNames('x__asset', { 'ramp-rellax': rellax })}
        data-rellax-speed={rellax ? (index + 1) * -0.5 : 0}
        role="presentation"
      >
        <img src={`/img/logos/${asset}.svg`} alt={asset} />
      </span>
    ))}
  </div>
)

const HeaderSection = ({ page, data, assets }) => (
  <header className="x-header">
    <div className="x-header__background ramp-rellax" data-rellax-speed={-4}>
      <div className="x-header__background__image">
        <Img fluid={buildImageObject(images.background)} durationFadeIn={2000} />
      </div>
    </div>

    <div className="x-header__content">
      {assets && <Assets assets={assets} rellax />}

      <Heading page={page} />
      <Heading page={page} section="header" type="h2" />

      {data.link && (
        <div className="x-header__stores">
          <Button size="largest" copy={data.link.copy} to={data.link.path} />
        </div>
      )}
    </div>
  </header>
)

const FeesSection = ({ page, data }) => (
  <section className="x-fees">
    <span className="x-fees__number">{data.number}</span>
    <h3 className="x-header__subheading">{data.heading}</h3>
  </section>
)

const AssetsSection = ({ page, heading, assets }) => (
  <section className="x-assets">
    <Assets assets={assets} />
    <h3 className="x-header__subheading">{heading}</h3>
  </section>
)

// Main component:
const RampLayout = ({ page }) => {
  const data = (key) => fetchPageData(page, 'Contents', key)

  useRellax({ className: 'ramp-rellax', center: false })

  /* Return content */
  return (
    <div className="x-page-ramp">
      <Header background="black" social />

      <main className="x">
        {data('header') && (
          <HeaderSection page={page} data={data('header')} assets={data('assetSymbols')} />
        )}
        {data('fees') && <FeesSection page={page} data={data('fees')} />}
        {data('assetSymbols') && data('header')?.supportedAssets && (
          <AssetsSection
            page={page}
            heading={data('header').supportedAssets}
            assets={data('assetSymbols')}
          />
        )}
        {data('download') && (
          <DownloadSection
            page={page}
            section="download"
            copy={data('download').copy}
            path={data('download').path}
          />
        )}

        <Footer noBorder trustpilot={false} />
      </main>
    </div>
  )
}

// Main component props:
RampLayout.propTypes = {
  page: PropTypes.string,
}

RampLayout.defaultProps = {}

export default RampLayout
